import React, { useEffect } from "react";
import { Box, Button, Modal, Typography, TextField } from "@mui/material";
import { Email, WhatsApp, Twitter, Facebook } from "@mui/icons-material";

interface IShareModal {
  open: boolean;
  handleClose: () => void;
  url: string;
  image: string;
  title: string;
  description: string;
}

const ShareModal: React.FC<IShareModal> = ({
  open,
  handleClose,
  url,
  image,
  title,
  description,
}) => {
  useEffect(() => {
    if (open) {
      const metaTags = [
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: description },
        { name: "twitter:image", content: image },
        { name: "twitter:url", content: url },
      ];

      metaTags.forEach((tag) => {
        const meta = document.createElement("meta");
        meta.name = tag.name;
        meta.content = tag.content;
        document.head.appendChild(meta);
      });

      return () => {
        metaTags.forEach((tag) => {
          const meta = document.querySelector(`meta[name="${tag.name}"]`);
          if (meta) {
            document.head.removeChild(meta);
          }
        });
      };
    }
  }, [open, url, image, title, description]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "1px solid grey",
          borderRadius: "6px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          Share this documentary
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          value={url}
          InputProps={{ readOnly: true }}
        />
        <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            component="a"
            href={`mailto:?subject=Check this out!&body=Check out this documentary: ${url}`}
            startIcon={<Email />}
          >
            Send Email
          </Button>
          <Button
            variant="contained"
            color="success"
            component="a"
            href={`https://api.whatsapp.com/send?text=Check out this documentary: ${url}`}
            startIcon={<WhatsApp />}
          >
            WhatsApp
          </Button>
          <Button
            variant="contained"
            color="info"
            component="a"
            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
              url
            )}&text=${encodeURIComponent(
              `${title}\n${description.substring(0, 70)}...\n`
            )}&hashtags=${encodeURIComponent("documentary")}`}
            startIcon={<Twitter />}
          >
            Twitter
          </Button>

          <Button
            variant="contained"
            color="primary"
            component="a"
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              url
            )}`}
            startIcon={<Facebook />}
          >
            Facebook
          </Button>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              mt: 2,
              backgroundColor: "#111111",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#FFFFFF",
                color: "#111111",
              },
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ShareModal;
