import React from 'react';
import { Box, Typography } from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

const NoDocsFound: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="54vh"
    >
      <SentimentDissatisfiedIcon style={{ fontSize: 80, color: '#ff1744' }} />
      <Typography variant="h3" color="textSecondary" gutterBottom>
        Upps!! No docs found.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Please try again later.
      </Typography>
    </Box>
  );
};

export default NoDocsFound;
