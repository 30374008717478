import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import VideoCard from "./VideoCard";
import { IDoc } from "../models/models";
import NoDocsFound from "./NoDocsFound";

export interface IDocsGrid {
  docsList: IDoc[];
  alignment: boolean;
  docsLoaded: boolean;
}

const DocsGrid: React.FC<IDocsGrid> = ({ docsList, alignment, docsLoaded }) => {
  if (docsList.length === 0 && docsLoaded) return <NoDocsFound />;

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginBottom: "6px",
        backgroundColor: "transparent",
      }}
    >
      <Grid
        container
        rowSpacing={6}
        columnSpacing={2}
        justifyContent="center"
        alignItems="center"
        padding={1}
      >
        {docsList.map((doc) => {
          return (
            <Grid key={doc._id} xs={12} sm={6} md={4} lg={3}>
              <VideoCard key={doc._id} docInfo={doc} alignment={alignment} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default DocsGrid;
