import React, { useState } from "react";
import Select from "react-select";
import { barCustomStyles } from "../styles/colorPalette";

interface Option {
  value: string;
  label: string;
}
export interface IAppFilterDropdown {
  setFilterBy: (filterBy: string | null) => void;
}

const AppFilterDropdown: React.FC<IAppFilterDropdown> = ({ setFilterBy }) => {
  const options: Option[] = [
    { value: "all", label: "All" },
    { value: "conspiracy", label: "Conspiracy" },
    { value: "health", label: "Health" },
    { value: "spirituality", label: "Spirituality" },
    { value: "archeology", label: "Archeology" },
    { value: "mystery", label: "Mystery" },
    { value: "ufo", label: "Ufo" },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    setFilterBy(selectedOption?.value);
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      placeholder={`Filter by genre: All`}
      isClearable
      isSearchable={false}
      defaultValue={options[0]}
      styles={barCustomStyles}
    />
  );
};

export default AppFilterDropdown;
