import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../pages/Homepage";
import SingleDoc from "../pages/SingleDoc";
import ErrorPage from "../pages/ErrorPage";
import { IDoc } from "../models/models";
import DrawerAppBar from "../components/AppBar";
import ContactForm from "../pages/ContactForm";
import TagSearchResults from "../pages/TagSearchResults.";
import UserSubscribe from "../pages/UserSubscribe";

export interface INavigator {
  docList: IDoc[];
  setDocList: (docList: IDoc[]) => void;
}

const Navigator: React.FC<INavigator> = ({ docList, setDocList }) => {
  const [numberOfDocs, setNumberOfDocs] = useState<number>(0);

  return (
    <BrowserRouter>
      <DrawerAppBar numberOfDocs={numberOfDocs} />
      <Routes>
        <Route
          path="/"
          element={
            <Homepage
              docList={docList}
              setDocList={setDocList}
              setNumberOfDocs={setNumberOfDocs}
            />
          }
        />
        <Route
          path="/singleDoc/:id"
          element={
            <SingleDoc setNumberOfDocs={setNumberOfDocs} docList={docList} />
          }
        />
        <Route
          path="/tag/:tag"
          element={
            <TagSearchResults
              allDocs={docList}
              setNumberOfDocs={setNumberOfDocs}
            />
          }
        />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/subscribe" element={<UserSubscribe />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigator;
