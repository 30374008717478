import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IDoc } from "../models/models";
import DocsGrid from "./DocsGrid";
import FeaturedDoc from "./FeaturedDoc";

interface PaginatedContentProps {
  itemsPerPage: number;
  items: IDoc[];
  alignment: boolean;
  page: number;
  setPage: (page: number) => void;
  editorChoice: boolean;
  docsLoaded: boolean;
}

const PaginatedContent: React.FC<PaginatedContentProps> = ({
  itemsPerPage,
  items,
  alignment,
  page,
  setPage,
  editorChoice,
  docsLoaded,
}) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const selectedItems = items.slice(startIndex, startIndex + itemsPerPage);
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Stack spacing={1} sx={{ marginBottom: "8px", backgroundColor: "#111111" }}>
      {isMobile && <div style={{ height: "84px" }}></div>}
      <div>
        {page === 1 && editorChoice && <FeaturedDoc />}
        <div
          style={{
            backgroundColor: "transparent",
            zIndex: 19,
            position: "relative",
            marginTop: `${page === 1 && editorChoice ? "-80px" : "-0px"}`,
          }}
        >
          <DocsGrid
            docsList={selectedItems}
            alignment={alignment}
            docsLoaded={docsLoaded}
          />
        </div>
      </div>

      <Pagination
        count={Math.ceil(items.length / itemsPerPage)}
        page={page}
        onChange={handleChange}
        variant="outlined"
        shape="rounded"
        sx={{
          alignSelf: "center",
          "& .MuiPaginationItem-root": {
            color: "#fff", // Change text color of pagination items
            borderColor: "#fff", // Change border color of pagination items
            padding: "10px 16px", // Increase button size
            fontSize: "1.1rem", // Increase font size
            minWidth: "48px", // Minimum width for buttons
          },
          "& .Mui-selected": {
            backgroundColor: "#ff5722 !important", // Change background color of the selected button
            color: "#fff !important", // Change text color of the selected button
            borderColor: "#ff5722 !important", // Ensure the border color matches the selected background
          },
          "& .MuiPaginationItem-ellipsis": {
            color: "#fff", // Change color of the ellipsis
          },
          "&:hover .MuiPaginationItem-root": {
            backgroundColor: "#333", // Change background color on hover
            color: "#fff", // Change text color on hover
          },
        }}
      />
    </Stack>
  );
};

export default PaginatedContent;
