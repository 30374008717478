import "./VideoCard.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Rating } from "@mui/material";
import { IDoc } from "../models/models";
import { capitalizeFirstChar } from "../utlis/capitalizeFirstChar";
import { Link } from "react-router-dom";

export interface IVideocard {
  docInfo: IDoc;
  alignment: string;
}

const TagVideoCard: React.FC<IVideocard> = ({ docInfo, alignment }) => {
  let videoCardHeight = alignment === "+ detail" ? 360 : 260;

  // Calculate if the docInfo.createdAt is less than 7 days old
  const isNew = (dateString: string) => {
    const createdAt = new Date(dateString);
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - createdAt.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 7;
  };

  return (
    <Link to={`/singleDoc/${docInfo?._id}`} style={{ textDecoration: "none" }}>
      <Card
        sx={{
          display: "flex",
          width: "100%",
          height: "128px",
          overflow: "hidden",
          boxShadow: 3,
          borderRadius: 2,
          position: "relative",
        }}
        className="video-card"
      >
        <CardMedia
          component="img"
          alt={docInfo?.title}
          image={docInfo?.image}
          sx={{ width: "25%", height: "100%", objectFit: "cover" }}
        />
        {isNew(docInfo.createdAt) && (
          <div
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              backgroundColor: "white",
              color: "black",
              padding: "5px 10px",
              borderRadius: "5px",
              fontSize: "12px",
              fontWeight: "bold",
              opacity: 0.9,
            }}
          >
            New!
          </div>
        )}
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "70%",
            overflow: "hidden",
          }}
        >
          <Typography
            gutterBottom
            variant="subtitle1"
            component="div"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {docInfo.title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
          >
            <span>
              {docInfo.duration}min / {capitalizeFirstChar(docInfo?.genre)}
            </span>
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <span style={{ fontSize: "14px", color: "grey" }}>
                ({docInfo.year})
              </span>
              <Rating value={docInfo.rating} readOnly max={5} size="small" />
            </div>
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default TagVideoCard;
