import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { VolumeOff, VolumeUp } from "@mui/icons-material";
import { Rating } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { capitalizeFirstChar } from "../utlis/capitalizeFirstChar";
const videoFile = require("./subs/intro.mp4");

const docInfo = {
  _id: "331",
  image:
    "https://static-3.bitchute.com/live/cover_images/dancpAAhIini/chIgM5p19qpV_640x360.jpg",
  title: "Final Days (2023)",
  rating: 5,
  duration: 65,
  genre: "health",
  year: 2023,
  description:
    "From the Directors of Died Suddenly: Final Days Exposes the Scientific Technological Elite and their desire to become gods. The Covid pandemic was the test run for an even more sinister plot to come.",
  tags: ["conspiracy", "health", "nwo"],
  otherSources: ["https://odysee.com/@JeffreyEpstein:1/finaldays:89"],
  shareLink: "https://seed132.bitchute.com/dancpAAhIini/chIgM5p19qpV.mp4",
  subs: true,
};

const AutoPlayVideo: React.FC = () => {
  const playerRef = useRef<ReactPlayer | null>(null);
  const [muted, setMuted] = useState(true); // State to manage sound
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:600px)");

  const handleWatch = () => {
    navigate(`/singleDoc/${docInfo?._id}`);
  };

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(0, "seconds"); // Start from the beginning
    }
  }, []);

  const toggleSound = () => {
    setMuted(!muted); // Toggle the muted state
  };

  return (
    <div
      style={{
        position: "relative", // Enable positioning for overlay
        width: "100%",
        marginTop: "-28px",
        height: isMobile ? "280px" : "600px",
        overflow: "hidden",
        zIndex: 2,
      }}
    >
      {/* ReactPlayer for the video */}
      <ReactPlayer
        ref={playerRef}
        url={videoFile}
        playing
        muted={muted} // Control muted state
        loop
        width="100%"
        height="auto"
        playsinline
        config={{
          file: { attributes: { autoPlay: true, muted: muted } },
        }}
      />

      {/* Dark overlay div */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 88%, rgba(17, 17, 17, 1))", // Gradient from semi-transparent black to white at the bottom
          zIndex: 4, // Hre it's on top of the video
        }}
      >
        {/* Text aligned to top left */}
        <div
          style={{
            position: "absolute",
            top: "20px", // Adjust as needed
            left: "20px", // Adjust as needed
            color: "white",
          }}
        >
          <br />
          <h3>Editor's pick</h3>
          {!isMobile && <br />}
          <h1 style={{ fontSize: isMobile ? "1rem" : "2rem" }}>
            {docInfo.title}
          </h1>
          <h3 style={{ fontSize: isMobile ? "0.8rem" : "1.2rem" }}>
            Genre: {capitalizeFirstChar(docInfo.genre)}
          </h3>
          <h3 style={{ fontSize: isMobile ? "0.8rem" : "1.2rem" }}>
            Duration: {docInfo.duration}
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <h3 style={{ fontSize: isMobile ? "0.8rem" : "1.2rem" }}>
              Rating:{" "}
            </h3>
            <Rating
              sx={{ marginTop: "2px", marginLeft: "2px" }}
              readOnly
              value={docInfo?.rating}
            />{" "}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              flexDirection: "row",
              marginTop: isMobile ? "-20px" : "0px",
            }}
          >
            <button
              onClick={handleWatch}
              style={{
                //   position: "absolute",
                //   bottom: "20px", // Adjust as needed
                //   right: "20px", // Adjust as needed
                marginTop: "24px",
                padding: "10px 20px",
                fontSize: isMobile ? "1rem" : "1.5rem",
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent button
                border: "none",
                cursor: "pointer",
                borderRadius: "5px",
              }}
            >
              Watch documentary
            </button>

            <button
              onClick={toggleSound}
              style={{
                marginTop: "24px",
                marginLeft: "10px",
                padding: isMobile ? "5px 15px" : "10px 20px", // Adjust padding based on isMobile
                fontSize: isMobile ? "0.9rem" : "1rem", // Adjust fontSize based on isMobile
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent button
                border: "none",
                cursor: "pointer",
                borderRadius: "5px",
              }}
            >
              {muted ? <VolumeOff /> : <VolumeUp />}
            </button>
          </div>
        </div>

        {/* Sound toggle button in bottom right */}
      </div>
    </div>
  );
};

export default AutoPlayVideo;
